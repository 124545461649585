import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLunr } from 'react-lunr'

import { getTrendingPost } from '../../helpers/get-trending-posts'
import { useCategories } from '../../hooks/useCategories'
import SearchView from './search-view'

const SearchController = () => {
  const { i18n } = useTranslation()
  const [, setMainCategory] = useState('')
  const [allResults, setAllResults] = useState([])
  const [allFilteredResults, setAllFilteredResults] = useState([])
  const [selectedCategories, setSelectedCategories] = useState({})
  const [selectedSortBy, setSelectedSortBy] = useState('ASC')
  const [categories, setCategories] = useState([])
  const [categoriesUniqueKeys, setcategoriesUniqueKeys] = useState([])
  const [, setUnversityLevelCategories] = useState([])
  const [, setUnversityTopicCategories] = useState([])

  const { withSubCategories } = useCategories()
  const queryData = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)
  const [query, setQuery] = useState('')
  const location = useLocation()

  useEffect(() => {
    const urlSearch = new URLSearchParams(location.search)
    if (urlSearch.get('keyword')) {
      setQuery(urlSearch.get('keyword'))
    }
    if (!urlSearch.has('keyword')) {
      const redirectTo = location.href.replace(/\?.*/, '')
      window.history.replaceState({}, '', redirectTo)
    }
  }, [location.href, location.search])

  const {
    localSearchPages: { index, store },
  } = queryData
  const filterKeyword = useLunr(query, index, store)
  const handleOnChangeFilters = (type, value) => {
    if (type === 'category') {
      setMainCategory(value)
      setSelectedCategories({ main: value })
      setUnversityLevelCategories([])
      setUnversityTopicCategories([])
    } else {
      switch (type) {
        case 'university-levels':
          setUnversityLevelCategories([value])
          setSelectedCategories({
            ...selectedCategories,
            'university-levels': value,
          })
          break
        case 'university-topics':
          setUnversityTopicCategories([value])
          setSelectedCategories({
            ...selectedCategories,
            'university-topics': value,
          })
          break
        default:
          setSelectedCategories({ ...selectedCategories, [type]: value })
          break
      }
    }
  }

  const handleSortBy = (type, value) => {
    setSelectedSortBy(value)
  }

  const sortBy = useCallback(
    (arrayResults) => {
      let resultsOrderBy
      switch (selectedSortBy) {
        case 'ASC':
          resultsOrderBy = arrayResults.sort(
            (a, b) => new Date(b.dateGmt) - new Date(a.dateGmt)
          )
          break
        case 'DES':
          resultsOrderBy = arrayResults.sort(
            (a, b) => new Date(a.dateGmt) - new Date(b.dateGmt)
          )
          break
        case 'trending':
          resultsOrderBy = getTrendingPost(arrayResults)
          break
        default:
          resultsOrderBy = arrayResults.sort(
            (a, b) => new Date(b.dateGmt) - new Date(a.dateGmt)
          )
          break
      }
      return resultsOrderBy
    },
    [selectedSortBy]
  )

  const cleanFilters = () => {
    setSelectedCategories({})
    setSelectedSortBy('ASC')
  }

  const sortByOptions = [
    {
      label: 'search.sort.ascend',
      value: 'ASC',
    },
    {
      label: 'search.sort.descend',
      value: 'DES',
    },
    {
      label: 'search.sort.relevance',
      value: 'trending',
    },
  ]

  useEffect(() => {
    const selectedCatKeys = Object.values(selectedCategories).filter((c) => !!c)
    if (selectedCatKeys.length > 0) {
      let resultAfterFilter = []
      resultAfterFilter = _.filter(allResults, (res) => {
        const articleCats = _.get(res, 'categories.nodes', []).map(
          (c) => c.description
        )
        const bolleans = _.map(selectedCatKeys, (scat) =>
          _.includes(articleCats, scat)
        )
        return bolleans.length > 0 && _.every(bolleans, (item) => item === true)
      })

      setAllFilteredResults(sortBy(resultAfterFilter))
    } else {
      setAllFilteredResults(sortBy(allResults))
    }
  }, [allResults, selectedCategories, selectedSortBy, sortBy])

  useEffect(() => {
    const arrayResults = _.filter(filterKeyword, (result) =>
      result.locale.locale.startsWith(i18n.language)
    )
    setAllResults(sortBy(arrayResults))
    setAllFilteredResults(sortBy(arrayResults))
  }, [i18n.language, filterKeyword, sortBy])

  useEffect(() => {
    const cat = _.map(withSubCategories, (ct) => ({
      id: ct.description,
      value: ct.description,
      description: ct.description,
      label: ct.name,
      children: ct.wpChildren.map((child) => ({
        label: child.name,
        value: child.description,
        description: child.description,
      })),
    }))
    const mainCats = _.filter(
      cat,
      //TODO: remove nft-stories in next release
      (c) => c.id !== 'university-levels'
    )
    setcategoriesUniqueKeys(mainCats)
    setCategories(cat)
  }, [withSubCategories])

  const viewProps = {
    allResults,
    allFilteredResults,
    categories,
    categoriesUniqueKeys,
    cleanFilters,
    handleOnChangeFilters,
    handleSortBy,
    sortByOptions,
    selectedSortBy,
    selectedCategories,
    query,
  }

  return <SearchView {...viewProps} />
}

export default SearchController

import './filter-search-mobile.scss'

import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import { ReactComponent as ChevronIcon } from '@/assets/icons/crypto_general_icons_chevron-down_clean.svg'
import { ReactComponent as CloseIcon } from '@/assets/icons/crypto_general_icons_close.svg'
import { Button, SearchBoxFilter } from '@/components'

const FilterSearchMobile = ({
  handleOnChangeFilters,
  categories,
  categoriesUniqueKeys,
  cleanFilters,
  handleSortBy,
  sortByOptions,
  selectedSortBy,
  selectedCategories,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const categoryForm = useRef(null)
  // const { scrollY } = useViewportScroll()
  // const scrollYChangeHandler = (y) => {
  //   const nextIsCollapsed = y > 0
  //   if (isCollapsed !== nextIsCollapsed) {
  //     setIsCollapsed(nextIsCollapsed)
  //   }
  // }
  // useEffect(() => scrollYChangeHandler(window.scrollY), [])
  // useEffect(() => scrollY.onChange(scrollYChangeHandler), [isCollapsed])

  return (
    <div
      className={classNames({
        'container-filter-mobile search-page': true,
        collapse: isCollapsed,
      })}
    >
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        bodyAttributes={{
          class: isOpen ? 'is-search-open fs-unmask' : 'fs-unmask',
        }}
      />
      {!isOpen && (
        <div className="box-filter-mobile">
          <p className="text-type-filters">Filter</p>
          <div className="box-icon-chevron" onClick={() => setIsOpen(true)}>
            <ChevronIcon className="icon-chevron" />
          </div>
        </div>
      )}
      <div
        className={`container-filters-mobile ${
          isOpen
            ? 'container-filters-mobile-open'
            : 'container-filters-mobile-close'
        }`}
      >
        <div className="box-filters-mobile">
          <div className="header-filter-mobile">
            <span className="closed-button" onClick={() => setIsOpen(false)}>
              <CloseIcon className="icon-close" />
            </span>
            <p className="title-filter-mobile">Filter</p>
            <span
              className="reset-button"
              onClick={() => {
                cleanFilters()
                categoryForm.current.resetForm()
              }}
            >
              Reset
            </span>
          </div>

          <div className="content-filters-mobile">
            <div>
              <SearchBoxFilter
                options={sortByOptions}
                type="sortBy"
                title="SORT BY"
                selectedSortBy={selectedSortBy}
                onChange={(value) => handleSortBy('sortBy', value)}
              />
              <SearchBoxFilter
                options={categoriesUniqueKeys}
                type="category"
                title="SECTIONS"
                selectedSortBy={selectedCategories.main}
                ref={categoryForm}
                onChange={(value) => handleOnChangeFilters('category', value)}
              />
              {selectedCategories.main === 'events' && (
                <SearchBoxFilter
                  title="EVENTS Types"
                  options={categories}
                  selectedSortBy={selectedCategories.events}
                  type="events"
                  onChange={(value) => handleOnChangeFilters('events', value)}
                />
              )}
              {selectedCategories.main === 'product-news' && (
                <SearchBoxFilter
                  title="Product News Topics"
                  type="product-news"
                  selectedSortBy={selectedCategories['product-news']}
                  options={categories}
                  onChange={(value) =>
                    handleOnChangeFilters('product-news', value)
                  }
                />
              )}
              {selectedCategories.main === 'company-news' && (
                <SearchBoxFilter
                  title="Company News Topics"
                  type="company-news"
                  selectedSortBy={selectedCategories['company-news']}
                  options={categories}
                  onChange={(value) =>
                    handleOnChangeFilters('company-news', value)
                  }
                />
              )}
              {selectedCategories.main === 'market-updates' && (
                <SearchBoxFilter
                  title="Market Updates Topics"
                  type="market-updates"
                  selectedSortBy={selectedCategories['market-updates']}
                  options={categories}
                  onChange={(value) =>
                    handleOnChangeFilters('market-updates', value)
                  }
                />
              )}
              {selectedCategories.main === 'university' && (
                <SearchBoxFilter
                  title="University Topics"
                  type="university"
                  selectedSortBy={selectedCategories['university-topics']}
                  options={categories}
                  onChange={(value) =>
                    handleOnChangeFilters('university-topics', value)
                  }
                />
              )}
              {selectedCategories.main === 'university' && (
                <SearchBoxFilter
                  title="University Levels"
                  type="university-levels"
                  selectedSortBy={selectedCategories['university-levels']}
                  options={categories}
                  onChange={(value) =>
                    handleOnChangeFilters('university-levels', value)
                  }
                />
              )}
              {selectedCategories.main === 'research' && (
                <SearchBoxFilter
                  title="Report Types"
                  type="research"
                  selectedSortBy={selectedCategories.research}
                  options={categories}
                  onChange={(value) => handleOnChangeFilters('research', value)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="button-filter-mobile">
          <Button title="apply" onPress={() => setIsOpen(false)} />
        </div>
      </div>
    </div>
  )
}

export default FilterSearchMobile

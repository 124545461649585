import './search-style.scss'

import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AdsSection, CardList, Seo } from '../../components'
import { FilterSearch, FilterSearchMobile } from './components'

const SearchView = ({
  handleOnChangeFilters,
  categories,
  categoriesUniqueKeys,
  cleanFilters,
  handleSortBy,
  sortByOptions,
  selectedSortBy,
  selectedCategories,
  allFilteredResults,
  query,
}) => {
  const { i18n } = useTranslation()
  return (
    <div className="search-container">
      <Seo
        title="Your Search Results | Crypto.com"
        description="You searched for (search word)."
        link={'https://crypto.com/search'}
        lang={i18n.language}
      />
      <FilterSearchMobile
        handleOnChangeFilters={handleOnChangeFilters}
        handleSortBy={handleSortBy}
        sortByOptions={sortByOptions}
        selectedSortBy={selectedSortBy}
        selectedCategories={selectedCategories}
        categories={categories}
        categoriesUniqueKeys={categoriesUniqueKeys}
        cleanFilters={cleanFilters}
      />
      <div className="search-bar-results has-mobile-filter">
        <h3>Results for {`"${query}"`}</h3>
        <p>
          {!_.isEmpty(allFilteredResults) ? allFilteredResults.length : 0}{' '}
          results found{' '}
        </p>
      </div>

      <div className="search-box">
        <FilterSearch
          handleOnChangeFilters={handleOnChangeFilters}
          handleSortBy={handleSortBy}
          sortByOptions={sortByOptions}
          selectedSortBy={selectedSortBy}
          selectedCategories={selectedCategories}
          categories={categories}
          categoriesUniqueKeys={categoriesUniqueKeys}
          cleanFilters={cleanFilters}
          title="topics"
        />
        <div className="search-results-container">
          {allFilteredResults.length === 0 ? (
            <div className="empty-results-container">
              <p>
                <Trans i18nKey="search.noResult" />
              </p>
            </div>
          ) : (
            <CardList data={allFilteredResults} hasNoImage isSearch />
          )}
        </div>
      </div>
      <AdsSection />
    </div>
  )
}

export default SearchView

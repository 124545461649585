import './filter-search-styles.scss'

import React, { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, SearchBoxFilter } from '../../../../components'

const FilterSearch = ({
  handleOnChangeFilters,
  categories,
  categoriesUniqueKeys,
  cleanFilters,
  handleSortBy,
  sortByOptions,
  selectedSortBy,
  selectedCategories,
}) => {
  const categoryForm = useRef(null)
  const { t } = useTranslation()
  return (
    <aside className="search-aside">
      <div className="search-aside-action">
        <p className="search-aside-title">
          <Trans i18nKey="search.narrow"></Trans>
        </p>
        <Button
          title="Reset"
          small
          onPress={() => {
            cleanFilters()
            categoryForm.current.resetForm()
          }}
        />
      </div>
      <div className="search-aside-filters">
        <SearchBoxFilter
          options={sortByOptions}
          type="sortBy"
          title={t('search.sortBy')}
          selectedSortBy={selectedSortBy}
          onChange={(value) => handleSortBy('sortBy', value)}
        />
        <SearchBoxFilter
          options={categoriesUniqueKeys}
          type="category"
          title={t('search.section')}
          selectedSortBy={selectedCategories.main}
          ref={categoryForm}
          onChange={(value) => handleOnChangeFilters('category', value)}
        />
        {selectedCategories.main === 'events' && (
          <SearchBoxFilter
            title={t('search.events-types')}
            options={categories}
            selectedSortBy={selectedCategories.events}
            type="events"
            onChange={(value) => handleOnChangeFilters('events', value)}
          />
        )}
        {selectedCategories.main === 'product-news' && (
          <SearchBoxFilter
            title={t('search.product-news-topics')}
            type="product-news"
            selectedSortBy={selectedCategories['product-news']}
            options={categories}
            onChange={(value) => handleOnChangeFilters('product-news', value)}
          />
        )}
        {selectedCategories.main === 'market-updates' && (
          <SearchBoxFilter
            title={t('search.market-updates-topics')}
            type="market-updates"
            selectedSortBy={selectedCategories['market-updates']}
            options={categories}
            onChange={(value) => handleOnChangeFilters('market-updates', value)}
          />
        )}
        {selectedCategories.main === 'company-news' && (
          <SearchBoxFilter
            title={t('search.company-news-topics')}
            type="company-news"
            selectedSortBy={selectedCategories['company-news']}
            options={categories}
            onChange={(value) => handleOnChangeFilters('company-news', value)}
          />
        )}
        {selectedCategories.main === 'university' && (
          <SearchBoxFilter
            title={t('search.university-topics')}
            type="university"
            selectedSortBy={selectedCategories['university-topics']}
            options={categories}
            onChange={(value) =>
              handleOnChangeFilters('university-topics', value)
            }
          />
        )}
        {selectedCategories.main === 'university' && (
          <SearchBoxFilter
            title={t('search.university-levels')}
            type="university-levels"
            selectedSortBy={selectedCategories['university-levels']}
            options={categories}
            onChange={(value) =>
              handleOnChangeFilters('university-levels', value)
            }
          />
        )}
        {selectedCategories.main === 'research' && (
          <SearchBoxFilter
            title={t('search.report-types')}
            type="research"
            selectedSortBy={selectedCategories.research}
            options={categories}
            onChange={(value) => handleOnChangeFilters('research', value)}
          />
        )}
        {selectedCategories.main === 'nft-stories' && (
          <SearchBoxFilter
            title={t('search.nft-stories-topics')}
            type="nft-stories"
            selectedSortBy={selectedCategories['nft-stories']}
            options={categories}
            onChange={(value) => handleOnChangeFilters('nft-stories', value)}
          />
        )}
      </div>
    </aside>
  )
}

export default FilterSearch

import { graphql } from 'gatsby'
import React from 'react'

import SearchController from '../screens/search'

const Search = (props) => <SearchController {...props} />

export default Search

export const pageQuery = graphql`
  query {
    localSearchPages {
      index
      store
    }
  }
`
